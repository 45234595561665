<template>
  <div>
    <full-loader v-show="loading"></full-loader>

    <v-container fluid>
      <!-- breadcrum and previous router -->
     

      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>
      
      

      <button type="button" style="float: right;" 
              class="mb-3 mr-4 v-btn v-btn--contained theme--light v-size--small primary"  @click="buttonReset()" >
              <span class="v-btn__content">
                Reset All
              </span>
     </button>

      <div class="blockuser-contain">
        <vue-good-table
          ref="myTable"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true
          }"
          :rows="rows"
          :columns="columns"
          :sort-options="{
            enabled: true
          }"
          @on-cell-click="onCellClick"
        >
         <template #column-filter="props">   

        <!-- Column filter : email  -->
          <span v-if="props.column.label == 'Email'">
            <div class="d-flex justify-content-start">
              <div>
                 <input type="text" id="vgt-email" name="vgt-email" title="Search Email" class="col-auto vgt-input" v-model="inputMail" placeholder="Search email" autocomplete="off"  @input="handleInputEmail" />
              </div>
                 <button   type="button" v-if="serverParams.columnFilters.email" class="mx-1 btnMrgin mb-2 v-btn btn btn-sm theme--light primary" title="Refresh email" @click="resetColumnType('email')" ><v-icon class="text-white">mdi-close</v-icon></button> 
            </div>         
         </span>

        <!-- Column filter : full name  -->
         <span v-if="props.column.label == 'Fullname'">
           <div class="d-flex justify-content-start">
              <div>
              <input type="text" id="vgt-fullname" name="vgt-fullname" title="Search Fullname" class="col vgt-input" v-model="inputName" placeholder="Search Full name" autocomplete="off"  @input="handleInputFullname"/>
              </div>
               <button type="button" v-if="serverParams.columnFilters.fullname" class="mx-1 btnMrgin mb-2 v-btn btn btn-sm theme--light primary" title="Refresh full name" @click="resetColumnType('fullname')" ><v-icon class="text-white">mdi-close</v-icon></button> 
           </div>          
         </span>

           <!-- Column filter : Role -->
          <span v-if="props.column.label == 'Role'">
          
               <div class="d-flex justify-content-start">
                  <div>
                   <select name id
                      v-model="selectedType"
                      class="col vgt-select"
                      @change="selectedRoleTypeChange(selectedType)"
                      title="Select Role"
                     >
                    <option disabled value>Select Role</option>
                    <option
                        v-for="role in roles"
                        :key="role"
                        :value="role"
                        v-model="selectedType"
                      >
                      {{role}}
                      </option>
                    </select>
                  </div>
                    <button type="button" v-if="serverParams.columnFilters.role"  class="mx-1 btnMrgin mb-2 v-btn btn btn-sm theme--light primary" title="Refresh role" @click="resetColumnType('role')" ><v-icon class="text-white">mdi-close</v-icon></button> 
                </div>           
          </span>      

          <!-- Column filter : Created At -->
          <span v-if="props.column.label == 'Created At'">
              <div class="d-flex justify-content-start">
                  <div title="Select Date Range">
                      <input type="text"  class="col vgt-input" v-model="inputDate" placeholder="Select Date Range" id="datepicker" @input="onDateChange"  autocomplete="off"/>
                  </div>
                    <button type="button" v-if="serverParams.columnFilters.createdAt" class="mx-1 btnMrgin mb-2 v-btn btn btn-sm theme--light primary" title="Refresh date" @click="resetColumnType('createdAt')" ><v-icon class="text-white">mdi-close</v-icon></button> 
              </div>
            </span>   

          <!-- Column filter : Periodic block -->
          <span v-if="props.column.label == 'Periodic block'">
            <div class="d-flex justify-content-start">
                <div>
                   <select name id
                      v-model="selectedPeriodicType"
                      class="col vgt-select"
                      @change="selectedPeriodicTypeChange(selectedPeriodicType)"
                       title="Select Periodic Block"
                    >
                    <option disabled value>Select Periodic</option>
                    <option
                        v-for="periodic in periodicList"
                        :key="periodic"
                        :value="periodic"
                        v-model="selectedPeriodicType"
                      >
                      {{periodic}}
                      </option>
                   </select>
                </div>
                    <button type="button" v-if="serverParams.columnFilters.periodic_block" class="mx-1 btnMrgin mb-2 v-btn btn btn-sm theme--light primary" title="Refresh block" @click="resetColumnType('periodic_block')" ><v-icon class="text-white">mdi-close</v-icon></button> 
             </div>
          </span>  

        </template>
      </vue-good-table>
      
      </div>
    </v-container>
  </div>
</template>
<script>
import Mixins from '@/mixin';
import moment from 'moment';
import AdminAPI from '@/services/admin_api';
import FullLoader from '../components/full-loader.vue';
import flatPickr from "flatpickr";
import '.../../flatpickr/dist/flatpickr.css';
import '.../../flatpickr/dist/themes/material_green.css'; //light.css
// import customFooter from '../components/custom-footer.vue';
export default {
  name: 'tnb-logs',
  mixins: [Mixins],
  components: {
    'full-loader': FullLoader,
    // "custom-footer": customFooter,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'System Blocked Users',
          disabled: false,
          to: { name: 'email-logs' },
          exact: true,
        },
      ],
      isLoading: false,
      inputMail:"",
      inputName:"",
      selectedType :"",
      inputDate: "",
      selectedPeriodicType :"",
      flatpickrVal:"",
      roles :["All","Super Admin", "Admin",  "AdminClient", "Inspector", "User","Data Processing"],
      periodicList : ["All","In 3 months", "In 2 months", "In a month", "In 10-15 days","In 5-10 days","In 5 days","Tomorrow","Today","Already blocked"],
      components: {
        flatPickr
      },
      columns: [
        {
          label: 'Email',
          field: 'email',
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Search email', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          },
        },
        {
          label: 'Fullname',
          field: 'fullname',
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Search fullname', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
           
          },
        },
        {
          label: 'Role',
          field: 'role',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Select', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          },
        },
        {
          label: 'Created By',
          field: 'createdBy.fullname',
        },
        {
          label: 'Created At',
          field: 'createdAt',
          filterable: true,
          type: "date",
          tdClass: 'text-left',
          thClass: 'text-left',
          dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
          dateOutputFormat: 'yyyy-MM-dd', // outputs 2018-03-16
          filterOptions: {
            enabled: true,
            placeholder: "Select Date",
            filterValue: '', // initial populated value for this filter
            filterFn: this.dateRangeFilter,
            // filterFn: this.columnFilterFn, //custom filter function that
          }
        },
        // {
        //   label: 'Year Block Date',
        //   field: 'year_block_date',
        // },
        {
          label: 'Periodic block',
          field: 'periodic_block',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Select', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            // trigger: "enter" //only trigger on enter not on keyup
          },
        },
        {
          label: 'Periodic block Remove',
          field: 'periodic_block_remove',
          html: true,
          sortable: false,
        },
        {
          label: 'Invaild block ',
          field: 'invaild_block',
          sortable: false,
        },
        {
          label: 'Invaild block Remove ',
          field: 'invaild_block_remove',
          sortable: false,
          html: true,
        },
        {
          label: 'Year block status',
          field: 'year_block_status',
          sortable: false,
        },
        {
          label: 'Year block remove',
          field: 'year_block_remove',
          sortable: false,
          html: true,
        },
        {
          label: 'Block Status ',
          field: 'block_status',
          sortable: false,
          html: true,
        },
        {
          label: 'Delete User ',
          field: 'delete_user',
          sortable: false,
          html: true,
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: 'createdAt', // example: 'name'
            type: '-1', // 'asc' or 'desc'
          },
        ],
        page: 1,
        perPage: 10,
        loggedUser: {},
      },
      loading: false,
    };
  },
  methods: { 
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
     
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type == 'asc' ? 1 : -1,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
   
      if (params.columnFilters && params.columnFilters.hasOwnProperty("role")){
          let roleVal = params.columnFilters.role;
          params.columnFilters.role =  roleVal == 'All' ? '' : roleVal;
      }

      if (params.columnFilters && params.columnFilters.hasOwnProperty("periodic_block")){
          let periodicVal = params.columnFilters.periodic_block;
          params.columnFilters.periodic_block =  periodicVal == 'All' ? '' : periodicVal;
      }
     
      this.updateParams(params);
      this.loadItems();
    },
    // load items is what brings back the rows from server
    async loadItems() {
      
      this.loading = true;
      let loggedUser = JSON.parse(localStorage.getItem('userData'));
      if (this.$router.currentRoute.name == 'tnb-blocked-users-filter') {
        this.serverParams.type = 1;
      } else {
        this.serverParams.type = 0;
      }     

      this.serverParams.loggedUser = loggedUser;
      var data = await AdminAPI.post(
        `blockedUserList`,
        this.serverParams,
        true
      );
      this.loading = false;
      data = data.data;
      //------------------------------------------
      this.totalRecords = data.totalRecords;
      this.rows = data.rows;
      for (const key in this.rows) {
        if (this.rows.hasOwnProperty(key)) {
          const element = this.rows[key];
          if (!element.year_block_status && !element.year_block_date) {
            this.rows[key].year_block_status = '-';
            this.rows[key].year_block_remove = '-';
          } else if (element.year_block_status) {
            this.rows[key].year_block_status = 'Not Blocked';
            this.rows[key].year_block_remove = '-';
          } else if (!element.year_block_status && element.year_block_date) {
            this.rows[key].year_block_status = 'Blocked';
            this.rows[key].year_block_remove = `<button
              type="button"
              class="mr-4 v-btn v-btn--contained theme--light v-size--small primary"
            >
              <span class="v-btn__content">
                Unblock
              </span>
            </button>`;
          }

          if (element.periodic_block_on) {
            // var localTime = moment
            //   .utc(`${element.periodic_block_on}`)
            //   .format("YYYY-MM-DD HH:mm:ss");
            // localTime = localTime + " UTC";
            // localTime = moment(new Date(localTime)).format("YYYY-MM-DD HH:mm:ss");
            // var localTime = moment(localTime).fromNow();
            // this.rows[key].periodic_block = localTime;
            var localTime = moment(new Date(element.periodic_block_on)).format(
              'YYYY-MM-DD HH:mm:ss'
            );
            var localTime1 = moment(localTime).fromNow();
            this.rows[key].periodic_block = localTime1;
            var dif = moment().diff(element.periodic_block_on, 'minutes');
            var timeoverFlag = dif >= 0 ? true : false;
            if (timeoverFlag) {
              this.rows[key].periodic_block_remove = `<button
                type="button"
                class="mr-4 v-btn v-btn--contained theme--light v-size--small warning"
              >
                <span class="v-btn__content">
                  Unblock
                </span>
              </button>`;
            } else {
              this.rows[key].periodic_block_remove = `No Action Needed`;
            }
          } else {
            this.rows[key].periodic_block = 'Not Blocked';
            this.rows[key].periodic_block_remove = `No Action Needed`;
          }
          if (element.year_block_status == false) {
            this.rows[key].invaild_block = '-';
            this.rows[key].invaild_block_remove = `-`;
          } else if (element.invalid_login_block) {
            this.rows[key].invaild_block = 'Blocked';
            this.rows[key].invaild_block_remove = `<button
              type="button"
              class="mr-4 v-btn v-btn--contained theme--light v-size--small warning"
            >
              <span class="v-btn__content">
                Unblock
              </span>
            </button>`;
          } else {
            
            this.rows[key].invaild_block = 'Not Blocked';
            this.rows[key].invaild_block_remove = `No Action Needed`;

          }
          if (element.year_block_status == false) {
            this.rows[key].block_status = '-';
          } else if (element.is_active) {
            this.rows[key].block_status = `<button
              type="button"
              class="mr-4 v-btn v-btn--contained theme--light v-size--small error"
            >
              <span class="v-btn__content">
                Block
              </span>
            </button>`;
          } else {
            this.rows[key].block_status = `<button
              type="button"
              class="mr-4 v-btn v-btn--contained theme--light v-size--small warning"
            >
              <span class="v-btn__content">
                Unblock
              </span>
            </button>`;
          }
          this.rows[key].delete_user = `<button
              type="button"
              class="mr-4 v-btn v-btn--contained theme--light v-size--small error"
            >
              <span class="v-btn__content">
                Delete
              </span>
            </button>`;
          var localCtTime = moment(new Date(element.createdAt)).format(
            'YYYY-MM-DD' //'YYYY-MM-DD HH:mm:ss'
          );
          // .utc(`${element.createdAt}`)
          // .format("YYYY-MM-DD HH:mm:ss");
          // localCtTime = localCtTime + " UTC";
          // localCtTime = moment(localCtTime).format("YYYY-MM-DD HH:mm:ss");         
          this.rows[key].createdAt = element.createdAt
            ? localCtTime
            : 'Date Not available';
        }
      }
 
        this.textBoxConfigEmailAttributes();
        this.textBoxConfigNameAttributes();      
    },
    async remove_block(type, _id, blockType) {
      try {
        this.loading = true;
        var data = await AdminAPI.get(
          `RemoveUserblocked?type=${type}&_id=${_id}&blockType=${blockType}`,
          true
        );
        this.loading = false;
        data = data.data;
        await this.loadItems();
      } catch (error) {
        throw error;
      }
    },
    async blockActions(type, _id) {
      try {
        this.loading = true;
        var data = await AdminAPI.get(
          `block-user?type=${type}&_id=${_id}`,
          true
        );
        this.loading = false;
        data = data.data;
        await this.loadItems();
      } catch (error) {
        throw error;
      }
    },
    async deleteUser(_id) {
      try {
        this.$swal({
          title: 'Delete User',
          text: `Do you want to delete this User? Once it is deleted,Can't revert back!!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
        }).then(async (result) => {
          if (result.value) {
            this.loading = true;
            var data = await AdminAPI.delete(`delete-user?_id=${_id}`, true);
            this.loading = false;
            data = data.data;
            await this.loadItems();
            this.$swal('Deleted!', 'Seleted user has been deleted.', 'success');
          } else {
            this.$swal('Cancelled', 'Your User is safe :)', 'error');
          }
        });
      } catch (error) {
        throw error;
      }
    },
    async onCellClick(row, column, rowIndex) {
      if (
        row.event.target.innerText == 'UNBLOCK' &&
        row.column.field == 'periodic_block_remove'
      ) {
        await this.remove_block(1, row.row._id);
      } else if (
        row.event.target.innerText == 'UNBLOCK' &&
        row.column.field == 'year_block_remove'
      ) {
        await this.remove_block(1, row.row._id, 'year_block');
      } else if (
        row.event.target.innerText == 'UNBLOCK' &&
        row.column.field == 'invaild_block_remove'
      ) {
        await this.remove_block(2, row.row._id);
      } else if (
        row.event.target.innerText == 'UNBLOCK' &&
        row.column.field == 'block_status'
      ) {
        await this.blockActions(1, row.row._id);
      } else if (
        row.event.target.innerText == 'BLOCK' &&
        row.column.field == 'block_status'
      ) {
        await this.blockActions(2, row.row._id);
      } else if (
        row.event.target.innerText == 'DELETE' &&
        row.column.field == 'delete_user'
      ) {
        await this.deleteUser(row.row._id);
      }
    },
    dateRangeFilter(data, filterString) {
      let dateRange = filterString.split("to");
      let startDate = Date.parse(dateRange[0]);
      let endDate = Date.parse(dateRange[1]);    
      return (data =
        Date.parse(data) >= startDate && Date.parse(data) <= endDate);
    },
     buttonReset() {
      if (this.$refs["myTable"]) {
      
       this.resetColumnFilter();
       this.serverParams.page = 1;
       this.serverParams.perPage = 10;
       this.$refs["myTable"].reset();
       this.loadItems();
     
      }
    },
    resetColumnFilter(){
       this.serverParams.columnFilters.createdAt ='';
       this.serverParams.columnFilters.email ='';
       this.serverParams.columnFilters.fullname ='';
       this.serverParams.columnFilters.periodic_block ='';
       this.serverParams.columnFilters.role ='';
       this.selectedType = null;
       this.inputMail ='';
       this.inputName = '';
       this.selectedPeriodicType = null;
       this.textBoxConfigEmailAttributes();
       this.textBoxConfigNameAttributes();
       this.flatpickrVal.clear();
    },
     clearFilter(field) {
      try {
       
        let found = this.columns.find((c) => {
          return c.field == field
        });
        console.log("foundd",found);
        found.filterOptions.filterValue = "";
      
      } catch {
        alert(`Unable to clear ${field} filter`)
      }
    },
    handleInputEmail(event){
      this.beforeRestingFilterColumn();
      let emailVal = event.target.value;
      this.serverParams.columnFilters.email = emailVal;
      this.inputMail = emailVal;
      this.loadItems();
    },
    handleInputFullname(event){
       this.beforeRestingFilterColumn();
      let nameVal = event.target.value;
      this.serverParams.columnFilters.fullname = nameVal;
      this.inputName = nameVal;
      this.loadItems();
    },
    selectedRoleTypeChange(type) {
      this.beforeRestingFilterColumn();
      this.serverParams.columnFilters.role = type;
      this.loadItems();
    },
     async selectedPeriodicTypeChange(type){
      this.beforeRestingFilterColumn();
      this.serverParams.columnFilters.periodic_block = type;
     this.loadItems();    
   
    },
    beforeRestingFilterColumn(){
      this.$refs["myTable"].reset();
      this.serverParams.page = 1;
      this.serverParams.perPage = 10;   
    },
    resetColumnType(type){
        switch (type) {
          case "email":
            this.serverParams.columnFilters.email ='';
            this.inputMail = '';
            this.textBoxConfigEmailAttributes();
            break;
          case "fullname":
            this.serverParams.columnFilters.fullname ='';
            this.inputName = '';
            this.textBoxConfigNameAttributes();
            break;
          case "role":
            this.serverParams.columnFilters.role ='';
            this.selectedType = null;
            break;
          case "periodic_block":
            this.serverParams.columnFilters.periodic_block ='';
            this.selectedPeriodicType = null;
            break;
          case "createdAt": 
            this.serverParams.columnFilters.createdAt ='';
            let dateAttr = document.getElementById("datepicker");  
             if(dateAttr){
                dateAttr.value = null;
             }
            
            this.flatpickrVal.clear();
            this.inputDate = ""
            break;
        }
        this.beforeRestingFilterColumn();
        this.loadItems();
      },
      onDateChange(event){
        this.beforeRestingFilterColumn();
        let dateVal = event.target.value;
        this.serverParams.columnFilters.createdAt = dateVal;
        this.inputDate = dateVal;        
        this.loadItems();
      },
      textBoxConfigEmailAttributes(){
        let emailAttr = document.getElementById("vgt-email");       
        if(emailAttr){
        emailAttr.style.backgroundColor = "white";
        emailAttr.readOnly = false;
        }
        
      },
     textBoxConfigNameAttributes(){
      let fulNameAttr = document.getElementById("vgt-fullname");
       if(fulNameAttr){
          fulNameAttr.style.backgroundColor = "white";
           fulNameAttr.readOnly = false;
       }
    }

  },
  async mounted() {
      
     this.flatpickrVal =  flatPickr('#datepicker', {
        dateFormat: "Y-m-d",
        mode: "range",
        allowInput: false,
        altInput: true,
      });

    // // ** Date filter *
    //  let inputs = [
    //   // 'input[placeholder="Filter Received"]',
    //   'input[placeholder="Select Date"]'
    // ];
    // inputs.forEach(function(input) {
    //   flatPickr(input, {
    //     dateFormat: "Y-m-d",
    //     mode: "range",
    //     allowInput: false,
    //     altInput: true,
    //   });
    // });
    this.textBoxConfigEmailAttributes();
    this.textBoxConfigNameAttributes();

  },
  watch: {
    $route(to, from) {
      this.loadItems();
    },
  },
};
</script>

<style scoped>
.download-contain {
  float: right;
}

.text-white {
	color: #fff;
  font-size:15px;
}
.btnMrgin{
  margin:auto
}
</style>
